<template>

    <layout-default title="core.404.title">
        <div class="404">
            <hr />
            <h2 class="text-center width-expand margin-l-top margin-l-bottom">{{ translate('core.404.title') }}</h2>
            <p class="text-l text-center margin-xl-bottom">{{ translate('core.404.explain') }}</p>
            <div class="flex flex-center width-expand">
                <form-button @click="goHome"
                             theme="lead"
                             :full-width="true"
                             icon="chevron-inline-start">{{ translate('core.404.buttonCta') }}</form-button>
            </div>
        </div>
    </layout-default>

</template>

<script>

export default {
  name: 'PageNotFound',

  components: {

  },
    methods: {
        goHome () {
            return this.$router.push({name: 'index'});
        }
    }
}
</script>
